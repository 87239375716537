import React, {  Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

const HomeOne = lazy(() => import("./home/HomeOne"));
const HomeTwo = lazy(() => import("./home/HomeTwo"));
const HomeThree = lazy(() => import("./home/HomeThree"));
const About = lazy(() => import("./pages/About"));

const ConstrucciónReformas = lazy(() => import("./service/construccion-reformas"));
const RelacionesAgricolesEmpresariales = lazy(() => import("./service/relaciones-agricoles-empresariales"));
const ServicioDeLimpieza = lazy(() => import("./service/servicio-de-limpieza"));

const Comunidadesdevecinoscomunidadesdepropietarios = lazy(() => import("./service/Comunidadespropietarios"));
const DespachosyOficinas = lazy(() => import("./service/Despachosyoficinas"));
const Centrosdeportivos = lazy(() => import("./service/Centrosdeportivos"));
const Limpiezasenfabricaseindustrias = lazy(() => import("./service/Limpiezasenfabricaseindustrias"));
const Limpiezatrasmudanza = lazy(() => import("./service/Limpiezatrasmudanza"));
const Limpiezaacasasparticulares = lazy(() => import("./service/Limpiezaacasasparticulares"));



const Fontaneria = lazy(() => import("./service/fontaneria"));
const PerlitaEscayolaYeso = lazy(() => import("./service/perlita-escayola-yeso"));
const Mantenimiento = lazy(() => import("./service/mantenimiento"));
const PinturaYDecoración = lazy(() => import("./service/pintura-y-decoracion"));
const Electricidad = lazy(() => import("./service/electricidad"));
const Albanileria = lazy(() => import("./service/albanileria-"));


const Reclutamientoyselecciondetalento = lazy(() => import("./service/reclutamiento-y-seleccion-de-talento"));
const FlexibilidadyAdaptabilidad = lazy(() => import("./service/flexibilidad-y-adaptabilidad"));

const Procesodeseleccionriguroso = lazy(() => import("./service/proceso-de-seleccion-riguroso"));
const GestionAdministrativa = lazy(() => import("./service/gestion-administrativa"));
const Seguimientoysoportecontinuo = lazy(() => import("./service/seguimiento-y-soporte-continuo"));
const Reddetalento = lazy(() => import("./service/red-de-talento"));

const ServiceDetailsLeftSidebar = lazy(() => import("./service/ServiceDetailsLeftSidebar"));
const ServiceDetailsRightSidebar = lazy(() => import("./service/ServiceDetailsRightSidebar"));
const Projects = lazy(() => import("./project/Projects"));
const ProjectDetails = lazy(() => import("./project/ProjectDetails"));

const DesarrolloResidencial = lazy(() => import("./project/DesarrolloResidencial"));

const ProyectoIndustrial = lazy(() => import("./project/ProyectoIndustrial"));

const RenovacionDeEdificios = lazy(() => import("./project/RenovacionDeEdificios"));

const BlogLeftSidebar = lazy(() => import("./blog/BlogLeftSidebar"));
const BlogRightSidebar = lazy(() => import("./blog/BlogRightSidebar"));
const BlogDetailsLeftSidebar = lazy(() => import("./blog/BlogDetailsLeftSidebar"));
const BlogDetailsRightSidebar = lazy(() => import("./blog/BlogDetailsRightSidebar"));
const Contact = lazy(() => import("./pages/Contact"));
const NoMAtch = lazy(() => import("./pages/404"));


const App = () => {
  return (
    <Router>
        <Suspense fallback={<div />}>
            <Routes>
                <Route path="/" element={<HomeOne />} />
                <Route path="/home-one" element={<HomeOne />} />
                <Route path="/home-two" element={<HomeTwo />} />
                <Route path="/home-three" element={<HomeThree />} />
                <Route path="/about-us" element={<About />} />
                <Route path="/construccion-reformas" element={<ConstrucciónReformas />} />

                <Route path="/fontaneria" element={<Fontaneria />} />
                <Route path="/albanileria-" element={<Albanileria />} />
                <Route path="/perlita-escayola-yeso" element={<PerlitaEscayolaYeso />} />
                <Route path="/mantenimiento" element={<Mantenimiento />} />
                <Route path="/pintura-y-decoracion" element={<PinturaYDecoración />} />
                <Route path="/electricidad" element={<Electricidad />} />

                <Route path="/relaciones-agricoles-empresariales" element={<RelacionesAgricolesEmpresariales />} />

                <Route path="/reclutamiento-y-seleccion-de-talento" element={<Reclutamientoyselecciondetalento />} />
                <Route path="/flexibilidad-y-adaptabilidad" element={<FlexibilidadyAdaptabilidad />} />
                <Route path="/proceso-de-seleccion-riguroso" element={<Procesodeseleccionriguroso />} />
                <Route path="/gestion-administrativa" element={<GestionAdministrativa />} />
                <Route path="/seguimiento-y-soporte-continuo" element={<Seguimientoysoportecontinuo />} />
                <Route path="/red-de-talento" element={<Reddetalento />} />


                <Route path="/servicio-de-limpieza" element={<ServicioDeLimpieza />} />
      
                <Route path="/Comunidades-de-vecinos-comunidades-de-propietarios" element={<Comunidadesdevecinoscomunidadesdepropietarios />} />
                <Route path="/Despachos-y-oficinas" element={<DespachosyOficinas />} />
                <Route path="/Centros-deportivos" element={<Centrosdeportivos />} />
                <Route path="/Limpiezas-en-fabricas-e-industrias" element={<Limpiezasenfabricaseindustrias />} />
                <Route path="/Limpieza-tras-mudanza" element={<Limpiezatrasmudanza />} />
                <Route path="/Limpieza-a-casas-particulares-pisos-chalet" element={<Limpiezaacasasparticulares />} />


                <Route path="/service-details-left-sidebar" element={<ServiceDetailsLeftSidebar />} />
                <Route path="/service-details-right-sidebar" element={<ServiceDetailsRightSidebar />} />
                <Route path="/projects" element={<Projects />} />
                <Route path="/project-details" element={<ProjectDetails />} />

                <Route path="/desarrollo-residencial" element={<DesarrolloResidencial />} />

                <Route path="/proyecto-industrial" element={<ProyectoIndustrial />} />

                <Route path="/renovacion-de-edificios" element={<RenovacionDeEdificios />} />

                <Route path="/blog-left-sidebar" element={<BlogLeftSidebar />} />
                <Route path="/blog-right-sidebar" element={<BlogRightSidebar />} />
                <Route path="/blog-details-left-sidebar" element={<BlogDetailsLeftSidebar />} />
                <Route path="/blog-details-right-sidebar" element={<BlogDetailsRightSidebar />} />
                <Route path="/contact-us" element={<Contact />} />
                <Route path="*" element={<NoMAtch />} />
            </Routes>
        </Suspense>
      </Router>
  )
}

export default App